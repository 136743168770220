/*  File name: app.css
    Author name: Marcello Nunes Bernardes
    Website name: https://comp2112-final-marcellobernardes.onrender.com/
    File Description: Main CSS page, for styling the app
*/


.navbar {
    opacity: 0.8;
}

#MainTitle {
    font-size: 4em;
    color: #5690CF;
    padding-top: 1em
}

main {
    padding-top: 4.375em
}

article, section {
    font-size: 1.35em;
}

#contactForm {
    margin-bottom: 4em;
}